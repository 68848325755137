import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Registrationoverview.css';

function CourseRegistrations() {
  const [registrations, setRegistrations] = useState({});
  const courses = [
    'SalsaLadyStyling', 'bachata0', 'bachata1', 'bachata2',
    'SalsaLa0', 'SalsaLa1', 'SalsaLa2', 'Fuerza11_16',
    'Fuerza8_10', 'Fuerza5_7'
  ];

  useEffect(() => {
    const fetchRegistrations = async () => {
      let data = {};
      for (let course of courses) {
        const querySnapshot = await getDocs(collection(db, course));
        data[course] = querySnapshot.docs.map(doc => doc.data());
      }
      setRegistrations(data);
    };
    fetchRegistrations();
  }, []);

  return (
    <div>
      <h1>Anmälda deltagare</h1>
      {courses.map(course => (
        <div key={course} style={{ marginBottom: '20px', borderBottom: '1px solid gray', paddingBottom: '10px' }}>
          <h2>{course.replace(/([A-Z])/g, ' $1').trim()}</h2>
          {registrations[course]?.length > 0 ? (
            <ul>
              {registrations[course].map((entry, index) => (
                <li key={index}>{entry.foreName} {entry.foljName ? '& ' + entry.foljName : ''} - {entry.category}</li>
              ))}
            </ul>
          ) : (
            <p>Inga anmälningar än.</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default CourseRegistrations;
