import React, { useState } from 'react';
import './RegistrationForm.css';
import { db } from '../firebase.js';
import { collection, setDoc, doc } from 'firebase/firestore';

function CourseForm() {
  const [formData, setFormData] = useState({
    category: 'singel', // Default to singel
    Name: '',
    Personnummer: '',
    Address: '',
    Email: '',
    foljName: '',
    foljPersonnummer: '',
    foljAddress: '',
    foljEmail: '',
    kurs: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (!formData.kurs) {
      alert('Vänligen välj en kurs!');
      return;
    }

    const collectionName = formData.kurs;
    const documentName = `${formData.foreName}-${formData.foljName}-${formData.category}`;

    try {
      alert('Initierar formulärsändning...');
      const docRef = doc(collection(db, collectionName), documentName);
      await setDoc(docRef, { ...formData, documentName });
      alert('Formulär skickades framgångsrikt!');
      setFormData({
        Category: 'singel',
        Name: '',
        Personnummer: '',
        Address: '',
        Email: '',
        FoljName: '',
        FoljPersonnummer: '',
        FoljAddress: '',
        FoljEmail: '',
        Kurs: '',
        Barnetsnamn:'',
        Barnetsalder:'',
      });
    } catch (error) {
      console.error('Error during form submission:', error);
      alert('Ett oväntat fel inträffade. Vänligen försök igen.');
    }
  };

  const getAvailableCourses = () => {
    switch (formData.category) {
      case 'singel':
        return [
          { value: 'SalsaLadyStyling', label: 'Salsa Lady Styling' },
          { value: 'bachata0', label: 'Bachata nivå 0' },
          { value: 'bachata1', label: 'Bachata nivå 1' },
          { value: 'bachata2', label: 'Bachata nivå 2' },
          { value: 'SalsaLa0', label: 'Salsa LA nivå 0' },
          { value: 'SalsaLa1', label: 'Salsa LA nivå 1' },
          { value: 'SalsaLa2', label: 'Salsa LA nivå 2' },
        ];
      case 'par':
        return [
          { value: 'bachata0', label: 'Bachata nivå 0' },
          { value: 'bachata1', label: 'Bachata nivå 1' },
          { value: 'bachata2', label: 'Bachata nivå 2' },
          { value: 'SalsaLa0', label: 'Salsa LA nivå 0' },
          { value: 'SalsaLa1', label: 'Salsa LA nivå 1' },
          { value: 'SalsaLa2', label: 'Salsa LA nivå 2' },
        ];
      case 'fdk':
        return [
          { value: 'Fuerza11_16', label: 'Fuerza 11-16+ år' },
          { value: 'Fuerza8_10', label: 'Fuerza 8-10 år' },
          { value: 'Fuerza5_7', label: 'Fuerza 4-7 år' },
        ];
      default:
        return [];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="radio-group">
        <label className="radio-label">
          <input type="radio" name="category" value="singel" checked={formData.category === 'singel'} onChange={handleChange} />
          <span className="custom-radio"></span>
          Singel anmälan till pardans eller lady styling
        </label>
        <label className="radio-label">
          <input type="radio" name="category" value="par" checked={formData.category === 'par'} onChange={handleChange} />
          <span className="custom-radio"></span>
          Par anmälan till pardans
        </label>
        <label className="radio-label">
          <input type="radio" name="category" value="fdk" checked={formData.category === 'fdk'} onChange={handleChange} />
          <span className="custom-radio"></span>
          Anmäla till FUERZA DANCE KIDS
        </label>
      </div>

      {/* Dynamisk visning av fält baserat på kategori */}
      {formData.category === 'singel' && (
        <div>
          <label>Namn</label>
          <input type="text" name="Name" value={formData.foreName} onChange={handleChange} placeholder="Ange ditt namn" required />
          <label>Personnummer</label>
          <input type="text" name="Personnummer" value={formData.forePersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          <label>Adress</label>
          <input type="text" name="Address" value={formData.foreAddress} onChange={handleChange} placeholder="Ange din adress" required />
          <label>Epost</label>
          <input type="email" name="Email" value={formData.foreEmail} onChange={handleChange} placeholder="Ange din epost" required />
        </div>
      )}

      {formData.category === 'par' && (
        <div>
          <h1 className='forarefoljare'>Förare</h1>
          <label>Namn</label>
          <input type="text" name="Name" value={formData.foreName} onChange={handleChange} placeholder="Ange namn för förare" required />
          <label>Personnummer</label>
          <input type="text" name="Personnummer" value={formData.forePersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          <label>Adress</label>
          <input type="text" name="Address" value={formData.foreAddress} onChange={handleChange} placeholder="Ange adress för förare" required />
          <label>Epost</label>
          <input type="email" name="Email" value={formData.foreEmail} onChange={handleChange} placeholder="Ange epost för förare" required />
          <h1 className='forarefoljare'>Följare</h1>
          <label>Namn</label>
          <input type="text" name="FoljName" value={formData.foljName} onChange={handleChange} placeholder="Ange namn för följare" required />
          <label>Personnummer</label>
          <input type="text" name="FoljPersonnummer" value={formData.foljPersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          <label>Adress</label>
          <input type="text" name="FoljAddress" value={formData.foljAddress} onChange={handleChange} placeholder="Ange adress för följare" required />
          <label>Epost</label>
          <input type="email" name="FoljEmail" value={formData.foljEmail} onChange={handleChange} placeholder="Ange epost för följare" required />
        </div>
      )}

      {formData.category === 'fdk' && (
        <div>
          <label>Vårdnadshavares Name</label>
          <input type="text" name="Name" value={formData.foreName} onChange={handleChange} placeholder="Ange ditt namn" required />
          <label>Vårdnadshavares personnummer</label>
          <input type="text" name="Personnummer" value={formData.forePersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          <label>Vårdnadshavares adress</label>
          <input type="text" name="Adress" value={formData.foreAddress} onChange={handleChange} placeholder="Ange din adress" required />
          <label>Vårdnadshavares epost</label>
          <input type="email" name="Email" value={formData.foreEmail} onChange={handleChange} placeholder="Ange din epost" required />
          <label>Barnets namn</label>
          <input type="text" name="Barnetsnamn" value={formData.foreEmail} onChange={handleChange} placeholder="Ange din epost" required />
          <label>Barnets ålder</label>
          <input type="text" name="Barnetsalder" value={formData.foreEmail} onChange={handleChange} placeholder="Ange din epost" required />
        </div>
      )}

      {/* Kursfält (disabled tills personlig info är ifylld) */}
      <div>
        <label>Kurs</label>
        <select name="kurs" value={formData.kurs} onChange={handleChange}  required>
          <option value="">Välj kurs</option>
          {getAvailableCourses().map((course) => (
            <option key={course.value} value={course.value}>
              {course.label}
            </option>
          ))}
        </select>
      </div>
      <button type="submit">Anmäla till kursen</button>
    </form>
  );
}

export default CourseForm;
